
















































































































































































import { Component, Vue } from "vue-property-decorator";
import cardBox from "@/components/card-box/index.vue";
import pagination from "@/components/pagination/index.vue";
import { ClassRoom, UpgradeLevel, PageNum } from "@/tool/interface-index";
import {
  deleteClassRoom,
  getClassRoomList,
  upgradeSetting,
  getUpgradeTestServeInfo,
  setClassRoom,
  unregisterClassRoom,
} from "@/api/classRoom";
import * as _ from "lodash";
import { UserModule } from "@/store/modules/user";

@Component({
  name: "ClassRoomListPage",
  components: {
    cardBox,
    pagination,
  },
})
export default class extends Vue {
  private multipleSelection: ClassRoom[] = [];

  private query: {
    schoolName?: string;
    areaId?: string;
    schoolId?: string;
    keyWords?: string;
  } = {
    schoolName: "",
  };
  private pageNum: PageNum = {
    totalPage: 0,
    curPage: 1,
    pageSize: 10,
  };
  private tableData: Array<ClassRoom> = [];

  private get disabledOperation(): boolean {
    console.log(`roles:${UserModule.roles}`);
    if (this.$route.meta.roles) {
      let roled: boolean = false;
      for (let i = 0; i < this.$route.meta.roles.length; i++) {
        roled =
          roled ||
          UserModule.roles.some((item) => item == this.$route.meta.roles[i]);
        if (roled) {
          break;
        }
      }
      if (!roled) {
        return true;
      }
      console.log(`meta.roles:${this.$route.meta.roles},roled:${roled}`);

      if (
        UserModule.roles.some(
          (item) => item == "admin" || item == "platformAdmin"
        )
      ) {
        return false;
      }

      /*
      return (
        UserModule.roles.findIndex((role: string) => {
          return role.toLowerCase() == "teacher";
        }) >= 0
      );
      */
    }
    return true;
  }

  private upgradeLevels: { key: string; label: string; value: string }[] = [
    {
      key: "none",
      label: "忽略",
      value: "none",
    },
    {
      key: "hint",
      label: "新版提示",
      value: "hint",
    },
    {
      key: "confirm",
      label: "升级提示",
      value: "confirm",
    },
    {
      key: "required",
      label: "强制升级",
      value: "required",
    },
  ];

  private upgradeSettingForm: {
    classRoom: ClassRoom | undefined;
    upgradeLevel: UpgradeLevel;
  } = {
    classRoom: undefined,
    upgradeLevel: UpgradeLevel.none,
  };
  private upgradeSettingDialogVisible: boolean = false;
  private handleUpgradeSettingClick(classRoom: ClassRoom) {
    this.upgradeSettingForm.classRoom = classRoom;
    this.upgradeSettingForm.upgradeLevel = classRoom.upgradeLevel;

    this.upgradeSettingDialogVisible = true;
    console.log(
      `handleUpgradeSettingClick: ${this.upgradeSettingDialogVisible}`
    );
  }

  handleSelectionChange(val: ClassRoom[]) {
    this.multipleSelection = val;
  }

  private handlePostUpgradeSettingClick() {
    upgradeSetting({
      classRoomId: this.upgradeSettingForm.classRoom!.classRoomId,
      upgradeLevel: this.upgradeSettingForm.upgradeLevel,
    })
      .then((resp) => {
        this.upgradeSettingDialogVisible = false;
        this.upgradeSettingForm.classRoom!.upgradeLevel =
          this.upgradeSettingForm.upgradeLevel;
      })
      .catch((error) => {
        //
      });
  }

  private handleUpgradeSettingDialogClose(done: any) {
    this.upgradeSettingDialogVisible = false;
    /*
        this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
          */
  }

  private cancel(classRoom: ClassRoom) {
    this.$confirm("此操作将取消注册该教室, 是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        unregisterClassRoom(classRoom).then((res: any) => {
          if (res.code == 0) {
            this.$message({
              type: "success",
              message: "取消成功!",
            });
            this.getClassRoomListData();
          }
        });
      })
      .catch(() => {});
  }

  private deleteData(classRoomId: string) {
    this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        deleteClassRoom(classRoomId).then((res: any) => {
          if (res.code == 0) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getClassRoomListData();
          }
        });
      })
      .catch(() => {});
  }
  private loading: boolean = false;
  private async getClassRoomListData() {
    try {
      this.loading = true;
      const res = await getClassRoomList(
        _.merge(
          {
            pageSize: this.pageNum.pageSize,
            curPage: this.pageNum.curPage,
          },
          this.query
        )
      );
      this.tableData = res.data.items;
      this.pageNum.totalPage = res.data.totalPage;
    } finally {
      this.loading = false;
    }
  }

  private upDataPage() {
    this.getClassRoomListData();
  }
  private setClassRoom(classRoomId: string, type: string) {
    this.$router.push({
      path:
        type == "add" ? "newClassRoom" : type == "edit" ? "editClassRoom" : "",
      query: {
        classRoomId: classRoomId,
        type: type,
      },
    });
  }

  private upgradeLevelFormatter(row: ClassRoom, column: any) {
    const upgradeLevel = this.upgradeLevels.find((value) => {
      return value.key == row.upgradeLevel;
    });
    if (upgradeLevel) {
      return upgradeLevel.label;
    }
    return "(未设置)";
  }

  queryClassRoomClick() {
    this.pageNum.curPage = 1;
    this.getClassRoomListData();
  }

  activated() {
    this.getClassRoomListData();
  }

  private upgradeProductVersion: string = "";
  mounted() {
    getUpgradeTestServeInfo().then(({ data }) => {
      this.upgradeProductVersion = data.upgradeProductVersion;
    });
  }
}
