import request from '@/utils/request'

export const unregisterClassRoom = (classRoom: any) =>
  request({
    // 添加/更新考场信息
    url: '/v1/classRoom/unregister',
    method: 'POST',
    data: {
      classRoom

    }
  })



export const setClassRoom = (data: any) =>
  request({
    // 添加/更新考场信息
    url: '/v1/classRoom',
    method: 'POST',
    data
  })

export const getClassRoomList = (data: {
  schoolName?: string,
  areaId?: string,
  schoolId?: string,
  keyWords?: string,
  pageSize: number,
  curPage: number
}) =>
  request({
    // 获取考场信息列表
    url: '/v1/classRoomList',
    method: 'GET',
    params: data
  })

export const queryClassRoom = (classRoomId: string) =>
  request({
    //  获取考场信息by classRoomId
    url: `/v1/classRoom/${classRoomId}`,
    method: 'GET'
  })

export const deleteClassRoom = (classRoomId: string) =>
  request({
    // 删除考场信息by classRoomId
    url: `/v1/classRoom/${classRoomId}`,
    method: 'DELETE'
  })

export const upgradeSetting = (data: {
  classRoomId: string,
  upgradeLevel: string
}) =>
  request({
    // 升级配置
    url: '/v1/classRoom/software/upgradeSetting',
    method: 'POST',
    data
  })

export const updateSoftwareVersionInfo = (data: {
  classRoomId: string,
  softwareVersion: string
}) =>
  request({
    // 升级配置
    url: '/v1/classRoom/software/updateVersionInfo',
    method: 'POST',
    data
  })


export const getUpgradeTestServeInfo = () =>
  request({
    // 升级配置
    url: '/v1/upgradeTestServe',
    method: 'POST',
    data: {
      classRoomId: "",
      lastUpgradeHashCode: "",
      lastUpgradeProductVersion: ""
    }
  })





